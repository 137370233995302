import React from "react";
import { graphql } from "gatsby";

import { ChallengeBtn } from "./challenge-card.styles";
import ChallengeCard from "./challenge-card";
import { PlayIcon } from "./icons";

import * as WS from "./winners.styles";

const S = { ...WS, ChallengeBtn };

const WinnerBtn = (props) => (
  <S.ChallengeBtn {...props}>
    Watch Video <PlayIcon css={S.winnerIcon} fill={"none"} />
  </S.ChallengeBtn>
);

const WinnerTile = ({ winner, ...challenge }) => (
  <ChallengeCard
    {...challenge}
    css={S.winnerCard}
    Btn={winner ? <WinnerBtn url={winner.url} /> : undefined}
  >
    {winner && (
      <S.ProjectBox>
        <S.ProjectTitle>{winner.name}</S.ProjectTitle>
        <S.ProjectText>{winner.text}</S.ProjectText>
      </S.ProjectBox>
    )}
  </ChallengeCard>
);

WinnerTile.defaultProps = {
  winner: {},
};

export default WinnerTile;

export const winnerFragment = graphql`
  fragment Winner on ChallengesJson {
    winner {
      name
      text
      url
    }
  }
`;

export const winnerField = {
  label: "Winner",
  name: "winner",
  widget: "object",
  fields: [
    {
      label: "Name",
      name: "name",
      widget: "string",
      default: "",
      required: false,
    },
    {
      label: "Text",
      name: "text",
      widget: "text",
      default: "",
      required: false,
    },
    {
      label: "VideoUrl",
      name: "url",
      widget: "string",
      default: "",
      required: false,
    },
  ],
};

export const winnersField = {
  ...winnerField,
  name: "winnerEntries",
  widget: "list",
}