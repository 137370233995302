import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { desktop } from "../styles/breakpoints";
// import { clipBox } from "../styles/shared";
import { rem, fontSize, fontWeights, colors } from "../styles/utils";

export const WinnerBox = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${rem(-90)};

  @media (${desktop}) {
    margin-bottom: ${rem(-90)};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: ${rem(382)};
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    background: linear-gradient(286.77deg, #b642a0 0%, #2f23ad 100%);

    @media (${desktop}) {
      bottom: ${rem(402)};
    }
  }
`;

export const WinnerTitle = styled.h1`
  position: relative;
  margin: ${rem(40)} auto ${rem(-60)};
  padding: 0 ${rem(15)};
  ${fontSize(30, 46)};
  font-weight: ${fontWeights.book};
  color: ${colors.white};
  text-align: center;
  z-index: 1;
`;

export const ProjectBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const ProjectTitle = styled.h3`
  ${fontSize(14, 20)};
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
  margin: 0 0 ${rem(10)} 0;
  padding: 0;
`;

export const ProjectText = styled.p`
  ${fontSize(18, 28)};
  font-weight: ${fontWeights.book};
  margin: 0;
  padding: 0;
`;

export const winnerCard = css`
  margin: ${rem(30)} auto ${rem(60)};
`;

export const winnerIcon = css`
  height: ${rem(30)};
  width: auto;
  z-index: 2;
  margin: ${rem(-6)} ${rem(-4)};
`;

export const ConfettiBox = styled.div`
  position: absolute;
  top: calc(100% - 200px);
  left: 50%;
  transform: translate3d(0, -50%, 0);
  width: ${rem(10)};
  height: ${rem(10)};
  pointer-events: none;
`;
